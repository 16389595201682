var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-progress", style: `width:${_vm.width}px;` },
    [
      _c("div", { staticClass: "m-progress-outer" }, [
        _c("div", { staticClass: "m-progress-inner" }, [
          _c("div", {
            class: ["u-progress-bg", { "u-success-bg": _vm.progress >= 100 }],
            style: `width: ${
              _vm.progress >= 100 ? 100 : _vm.progress
            }%; height: ${_vm.strokeWidth}px;`,
          }),
        ]),
      ]),
      _vm.showInfo ? void 0 : _vm._e(),
      _vm.progress >= 100
        ? _c(
            "svg",
            {
              staticClass: "u-success",
              attrs: {
                viewBox: "64 64 896 896",
                "data-icon": "check-circle",
                "aria-hidden": "true",
                focusable: "false",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z",
                },
              }),
            ]
          )
        : _c("p", { staticClass: "u-progress-text" }, [
            _vm._v(_vm._s(_vm.progress >= 100 ? 100 : _vm.progress) + "%"),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }