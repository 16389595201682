import axios from 'axios'
import Vue from 'vue'
import { ACCESS_TOKEN } from '@/store/mutation-types'
/**
 * 文件上传进度
 */
export async function upload(url, formData, uploadProcess) {
  console.log(url, formData,123)
  const token = Vue.ls.get(ACCESS_TOKEN)
  return axios.post(url, formData, {
    headers: {
      'X-Access-Token': token
    },
    onUploadProgress: progressEvent => {
      let percent = ((progressEvent.loaded / progressEvent.total) * 100) | 0
      uploadProcess && uploadProcess(percent)
    }
  })
}
